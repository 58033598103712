.inline-form-items {
    display: flex;
    gap: 8px;
  }

.custom-select .ant-select-selector {
    height: 50px !important;
    display: flex;
    align-items: center;
}
  
.custom-incident-select .ant-select-selection-item {
    line-height: 40px !important;
}

.custom-incident-select .ant-select-selector {
  height: 40px !important;
  display: flex;
  align-items: center;
}

.custom-select .ant-select-selection-item {
  line-height: 50px !important;
}

.rounded-input .ant-input,
.rounded-input .ant-input-password {
  border-radius: 20px !important;
}