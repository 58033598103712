/* Reset and force styles with high specificity */
.layout-dashboard .criclebox .ai-chart-container div[class*="nlux-"] {
    font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  }
  
  /* Force container styles */
  .layout-dashboard .criclebox .ai-chart-container {
    height: 100% !important;
    padding: 16px !important;
    background: transparent !important;
  }
  
  /* Target all nlux elements */
  [class*="nlux-"] {
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Force message styles */
  div[class*="nlux-conversation-message"] {
    margin: 8px 0 !important;
    padding: 12px 16px !important;
    border-radius: 12px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05) !important;
  }
  
  /* User messages */
  div[class*="nlux-conversation-message-user"] {
    background-color: #f5f5f5 !important; /* Light gray background */

  }
  
  div[class*="nlux-conversation-message-user"] * {
    color: rgb(109, 32, 32) !important;
  }
  
  /* Assistant messages */
  div[class*="nlux-conversation-message-assistant"] {
    background-color: white !important;
  }
  
  div[class*="nlux-conversation-message-assistant"] * {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  
  /* Input area */
  div[class*="nlux-composer"] {
    background: white !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 8px !important;
    margin: 8px !important;
    padding: 8px !important;
    position: relative !important;
    z-index: 1 !important;
  }
  
  /* Conversation starters */
  div[class*="nlux-conversation-starter"] {
    background-color: white !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 8px !important;
    margin: 4px !important;
    padding: 8px 12px !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  div[class*="nlux-comp-sendIcon-container"]  {
    background-color: #ffffff !important;

    color: #fff !important;
    border: none !important;
  }

  div[class*="nlux-comp-composer nlux-composer--typing"] textarea {
    border: none !important;
  }

  div[class*="nlux-comp-composer nlux-composer--typing"] button {
    border: none !important;
  }

 div[class*="nlux-composer-container"] {
    background-color: #ffffff !important;
    border: none !important;
  }

  /* Style the AIChat composer */
.ai-chat-composer {
  background-color: #f0f0f0 !important; /* Lighter gray background */
  border-radius: 8px !important; /* Add border radius */
  padding: 10px !important; /* Add padding */
  font-size: 14px !important; /* Change font size */
  color: #333 !important; /* Change text color */
  border: 1px solid #ccc !important; /* Add border */
}

/* Additional styles for the input field */
.ai-chat-composer input {
  border: none !important; /* Remove border */
  outline: none !important; /* Remove outline */
  width: 100% !important; /* Full width */
  padding: 8px !important; /* Add padding */
  font-size: 14px !important; /* Change font size */
  border-radius: 4px !important; /* Add border radius */
}

/* Additional styles for the send button */
.ai-chat-composer button {
  background-color: #007bff !important; /* Change background color */
  color: #fff !important; /* Change text color */
  border: none !important; /* Remove border */
  padding: 8px 16px !important; /* Add padding */
  font-size: 14px !important; /* Change font size */
  border-radius: 4px !important; /* Add border radius */
  cursor: pointer !important; /* Change cursor to pointer */
}

.ai-chat-composer button:hover {
  background-color: #0056b3 !important; /* Change background color on hover */
}

