/* Style the AIChat message bar */
.ai-chat-message-bar {
    background-color: #f0f0f0 !important; /* Change background color */
    border-radius: 8px !important; /* Add border radius */
    padding: 10px !important; /* Add padding */
    font-size: 14px !important; /* Change font size */
    color: #333 !important; /* Change text color */
    border: 1px solid #ccc !important; /* Add border */
  }
  
  /* Additional styles for the input field */
  .ai-chat-message-bar input {
    border: none !important; /* Remove border */
    outline: none !important; /* Remove outline */
    width: 100% !important; /* Full width */
    padding: 8px !important; /* Add padding */
    font-size: 14px !important; /* Change font size */
    border-radius: 4px !important; /* Add border radius */
  }
  
  /* Additional styles for the send button */
  .ai-chat-message-bar button {
    background-color: #007bff !important; /* Change background color */
    color: #fff !important; /* Change text color */
    border: none !important; /* Remove border */
    padding: 8px 16px !important; /* Add padding */
    font-size: 14px !important; /* Change font size */
    border-radius: 4px !important; /* Add border radius */
    cursor: pointer !important; /* Change cursor to pointer */
  }
  
  .ai-chat-message-bar button:hover {
    background-color: #0056b3 !important; /* Change background color on hover */
  }